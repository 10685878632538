import React, { useEffect, useState } from "react";
import PdfHeader from "../Components/pdfHeaderV1";
import {
  Document,
  Image,
  Line,
  Page,
  PDFViewer,
  Rect,
  StyleSheet,
  Svg,
  Text,
  View,
} from "@react-pdf/renderer";
import PdfHeading from "../Components/pdfHeading";
import UtilDateTime from "../../../util/UtilDateTime";
import ValueFormat from "../../../util/ValueFormat";
import {
  SC_COMPANY_NAME,
  SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant";
import { useLocation } from "react-router-dom";
import PdfFooter from "../Components/pdfFooterV1";

import { displayText } from "../../../constant/MessageConstant";
import { globalPDF } from "../../../assets/css/commonCssTextForPdf";
import { Column } from "jspdf-autotable";

import rank from "../../../assets/images/adminDashboard/pdf/pdf_rank.png";
import firstName from "../../../assets/images/adminDashboard/pdf/pdf_firstname.png";
import username from "../../../assets/images/adminDashboard/pdf/pdf_username.png";
import efficiency from "../../../assets/images/adminDashboard/pdf/pdf_efficiency.png";
import totalRides from "../../../assets/images/adminDashboard/pdf/pdf_total_rides.png";
import drivingScore from "../../../assets/images/adminDashboard/pdf/pdf_driving_score.png";
import anticipation from "../../../assets/images/adminDashboard/pdf/pdf_anticipation.png";
import selfConfidence from "../../../assets/images/adminDashboard/pdf/pdf_self_confidence.png";
import drivingSkill from "../../../assets/images/adminDashboard/pdf/pdf_driving_skill.png";
import drivingStyle from "../../../assets/images/adminDashboard/pdf/pdf_driving_style.png";
import drivingState from "../../../assets/images/adminDashboard/pdf/pdf_driver_state.png";
import mobScreen from "../../../assets/images/adminDashboard/pdf/pdf_mobile_screen.png";
import mobCall from "../../../assets/images/adminDashboard/pdf/pdf_mobile_call.png";
import kmsDriven from "../../../assets/images/adminDashboard/pdf/pdf_kms_driven.png";
import stressStrain from "../../../assets/images/adminDashboard/pdf/pdf_stress_strain.png";
import tdistance from "../../../assets/images/adminDashboard/pdf_distance.png";
import tduration from "../../../assets/images/adminDashboard/pdf_duration.png";
import i5 from "../../../assets/images/adminDashboard/pdf_high_alerts.png";
import i6 from "../../../assets/images/adminDashboard/pdf_medium_alerts.png";
import i7 from "../../../assets/images/adminDashboard/pdf_overspeed.png";
import i8 from "../../../assets/images/adminDashboard/pdf_mobile_call.png";
import i9 from "../../../assets/images/adminDashboard/pdf_mobile_screen.png";
import i10 from "../../../assets/images/adminDashboard/pdf_acceleration.png";
import i11 from "../../../assets/images/adminDashboard/pdf_braking.png";
import i12 from "../../../assets/images/adminDashboard/pdf_cornering.png";
import crash from "../../../assets/images/adminDashboard/pdf/pdf_crash.png";


import i4 from "../../../assets/images/adminDashboard/totalRides.svg";
import UserNameIcon from "../../../assets/images/adminDashboard/pdf/userNameImagePdf.png";
import LocationIcon from "../../../assets/images/adminDashboard/pdf/locationIconPdf.png";
import MinsIcon from "../../../assets/images/adminDashboard/pdf/minsIconPdf.png";
import AndroidIcon from "../../../assets/images/adminDashboard/pdf/androidIconPdf.png";
import WheelerIcon from "../../../assets/images/adminDashboard/pdf/wheelerIconPdf.png";
import ActivedateIcon from "../../../assets/images/adminDashboard/pdf/ActiveDateIconPdf.png";
import LastRideIcon from "../../../assets/images/adminDashboard/pdf/lastRideDateIcon.png";
import AnticipationIcon from "../../../assets/images/adminDashboard/pdf/AnticipationIconPdf.png";
import SelfConfidenceIcon from "../../../assets/images/adminDashboard/pdf/SelfconfidenceIconPdf.png";
import DrivingSkillcon from "../../../assets/images/adminDashboard/pdf/DrivingskillIconPdf.png";
import DrivingStylelcon from "../../../assets/images/adminDashboard/pdf/DrivingstyleIcon.png";
import DrivingStatelcon from "../../../assets/images/adminDashboard/pdf/DrivingStateIcon.png";
import MobileUsagelcon from "../../../assets/images/adminDashboard/pdf/MobileUsageIcon.png";
import OverSpeedlcon from "../../../assets/images/adminDashboard/pdf/OverSpeedIcon.png";
import Abclcon from "../../../assets/images/adminDashboard/pdf/AbcIcon.png";
import Countlcon from "../../../assets/images/adminDashboard/pdf/countIcon.png";
import CallCountlcon from "../../../assets/images/adminDashboard/pdf/CallcountIcon.png";
import ScreenCountlcon from "../../../assets/images/adminDashboard/pdf/ScreencountIcon.png";
import steeringWheel from "../../../assets/images/adminDashboard/pdf/steeringWheelIcon.png";
import IosIcon from "../../../assets/images/adminDashboard/pdf/iosIconPdf.png";

import totalRidesIcon from "../../../assets/images/adminDashboard/pdf/totalRidesIcon.png";
import userIconPdf from "../../../assets/images/adminDashboard/pdf/userIconPdf.png";
import totalDistanceIcon from "../../../assets/images/adminDashboard/pdf/totalDistanceIcon.png";
import totalDurationIcon from "../../../assets/images/adminDashboard/pdf/totalDurationIcon.png";
import highRiskIconPdf from "../../../assets/images/adminDashboard/pdf/highRiskIconPdf.png";


import CardImg1 from "../../../assets/images/t_animal_crossing.png";
import Caution from "../../../assets/images/t_caution.png";
import Curve from "../../../assets/images/t_curves.png";
import Round from "../../../assets/images/Round-About.png";
import Hillimg from "../../../assets/images/t_hill.png";
import HillDownWards from "../../../assets/images/t_hill_downwards.png";
import hillUp from "../../../assets/images/t_hill_upwards.png";
import icyConditions from "../../../assets/images/t_icy_conditions.png";
import Intersection from "../../../assets/images/t_intersection.png";
import LaneMerge from "../../../assets/images/t_lane_merge.png";
import LowGearArea from "../../../assets/images/t_low_gear_area.png";
import NarrowRoad from "../../../assets/images/t_narrow_road.png";
import NoOverTaking from "../../../assets/images/t_no_overtaking.png";
import PedestrianCrossing from "../../../assets/images/t_pedestrian_crossing.png";
import Priority from "../../../assets/images/t_priority.png";
import RailwayCrossing from "../../../assets/images/t_railway_crossing.png";
import RiskOfGrounding from "../../../assets/images/t_risk_of_grouping.png";
import School from "../../../assets/images/t_school_zone.png";
import SlipperyRoads from "../../../assets/images/t_slippery_road.png";
import Stopimg from "../../../assets/images/t_stop.png";
import TrafficLight from "../../../assets/images/t_traffic_light.png";
import WindImg from "../../../assets/images/t_wind.png";
import WindingRoad from "../../../assets/images/t_winding_road.png";
import YieldImg from "../../../assets/images/t_yield.png";
import NormalImg from "../../../assets/images/Ride_Details/Normall.png";
import TiredImg from "../../../assets/images/Ride_Details/Tired.png";
import AngryImg from "../../../assets/images/Ride_Details/Angry.png";
import SportiveImge from "../../../assets/images/Ride_Details/Normal.png";
import EcoImg from "../../../assets/images/Ride_Details/Eco.png";
import ChaoticImg from "../../../assets/images/Ride_Details/Chaotic.png";
import AggressiveImg from "../../../assets/images/Ride_Details/Aggressive.png";
import AddressIcon from "../../../assets/images/pdf/RideList/addressIcon.png";
import LocationIconNew from "../../../assets/images/pdf/RideList/locationIcon.png";
import AccIcon from "../../../assets/images/pdf/RideList/accIcon.png";
import BrakIcon from "../../../assets/images/pdf/RideList/brakIcon.png";
import CorIcon from "../../../assets/images/pdf/RideList/corIcon.png";
import RdIcon from "../../../assets/images/pdf/RideList/rdIcon.png";
import BattryIcon from "../../../assets/images/pdf/RideList/battryIcon.png";
import JvIcon from "../../../assets/images/pdf/RideList/jvIcon.png";

import PdfFooterV1 from "../Components/pdfFooterV1";

// const styles = StyleSheet.create({
//   pdfViewer: {
//     height: "96%",
//     width: "100%",
//     marginTop: "20px",
//     marginBottom: "5px",
//   },
//   document: {
//     marginTop: "15px",
//     marginBottom: "5px",
//   },
//   page: {
//     width: "100%",
//   },
//   tablestyle: {
//     border: "1px solid black",
//     borderTop: "none",
//     borderRight: "none",
//     padding: "6px",
//     fontSize: "10px",
//     textAlign: "center",
//   },
// });

const fontBold = {
  fontFamily: "Helvetica-Bold",
};

const styles = StyleSheet.create({

    tableHeading:{
        fontSize: 10,
        fontFamily: "Helvetica-Bold",
        whiteSpace:"nowrap"

    },
    
  gridContainer: {
    marginBottom: 0,
  },
  rowContainer: {
    flexDirection: 'row', // Arrange items horizontally
    justifyContent: 'space-between',
    gap:10,
    marginBottom: 10, // Space between rows
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    textAlign:"left",
    border:"1px solid lightgrey",
   
    
  },
  cardContent: {
    display: "flex",
    flexDirection:"row",
    justifyContent: "space-between",
    textAlign:"left",
    padding:5
  },
  imageContainer: {
    display:"flex",
    justifyContent:"center",
    flexDirection:"column"
  },
  image: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  titleContainer: {
    display:"flex",
    justifyContent:"center",
    flexDirection:"column", 
    width:"70%"
  },
  title: {
    fontSize: 9,
    fontWeight: 'bold',
  },
  dataContainer: {},
  dataText: {
    fontSize: 12,
    color: '#555',
  },

  page: {
    padding: 30,
    paddingTop: 10,
  },
  header: {
    fontSize: 16,
    marginBottom: 15,
    marginTop:20
  },
  table: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 15,
  },
  // Cell styles with fixed width, height, column gap, and row gap
  cell: {
    width: '12%',  // Fixed width for columns (8 columns)
    height: 30,    // Fixed height for rows    
    marginRight: 5,  // Gap between columns   
    border: '1px solid lightgrey',
    borderTop:0,
    fontSize: 9,
    display: 'flex',
    flexDirection:"row",
    alignItems: 'center',
    justifyContent: 'center',
  },

  cellHeader: {
    width: '12%',  // Fixed width for columns (8 columns)
    height: 30,    // Fixed height for rows
    // padding: 5,
    marginRight: 5,  // Gap between columns
    // marginBottom: 5, // Gap between rows
    border: '1px solid lightgrey',   
    fontSize: 9,
    display: 'flex',
    flexDirection:"row",
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:globalPDF.GREY_COLOR,
    
  },
 
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },

  headerText:{
    fontFamily: "Helvetica-Bold",
    fontSize: 9,
    textAlign:"center"
    
  },
  headerTextDNA:{
    fontFamily: "Helvetica-Bold",
    fontSize: 9,
    textAlign:"center",
    color:"red"
    
  },
  mapText:{    
    fontSize: 9,
    textAlign:"center"
    
  }
});



const RideListAllSummaryPdf = (
  {
    apiDataRideList,
    totalRide,
    RideListPageNumber,
    RideListTotalPageCount,
    RideListWorkFilter,
    RideListfilter,
    RideListSearchText,
    RideListStartDateTime,
    RideListEndDateTime
   
    // UDMainDashboardSafetyTableData,
    // yearlyDatas,
    // UDMainDashboardTopRowItemsTemp,
    // USRideBoxCount,
    // overallDataProps,
    // getSelectedYearData,
    // lastOverAllSyData,
    // totalSafeRidePer,
    // aggregateData,
    // filtersData,
    // totalDays,
    // summaryDataUser
  }
) => {


 

  const pathname = useLocation();

  const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

  var currentDate = new Date();

  const heading = "User Dashboard (Overall Summary)";

  const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();
  
  const formatDate = (date) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata", // IST timezone
      timeZoneName: "short", // Adds timezone abbreviation dynamically
    };
  
    // Use Intl.DateTimeFormat to format the date
    const formatter = new Intl.DateTimeFormat("en-IN", options);
    const formattedDate = formatter.formatToParts(date);
  
    // Extract date, time, and timezone parts
    const day = formattedDate.find((part) => part.type === "day")?.value;
    const month = formattedDate.find((part) => part.type === "month")?.value;
    const year = formattedDate.find((part) => part.type === "year")?.value;
    const hour = formattedDate.find((part) => part.type === "hour")?.value;
    const minute = formattedDate.find((part) => part.type === "minute")?.value;
    const dayPeriod = formattedDate.find((part) => part.type === "dayPeriod")?.value.toUpperCase(); // Capitalize AM/PM
    const timeZone = formattedDate.find((part) => part.type === "timeZoneName")?.value;
  
    // Format the output as DD/MM/YYYY HH:MM AM/PM TZ
    return `${day}/${month}/${year} ${hour}:${minute} ${dayPeriod} ${timeZone}`;
  };
  
  const formattedDate = formatDate(currentDate);
  

    // let period = "";
    // let startDate = "";
    // let endDate = "";
    // if (filtersUserPage.durationFilter === "CURRENT_YEAR") {
    //   period = ValueFormat.smallLettersFormat(filtersUserPage.durationFilter);
    //   startDate = UtilDateTime.getCurrentYear(filtersUserPage.startDateTime);
    //   endDate = "";
    // } else if (filtersUserPage.durationFilter === "CURRENT_MONTH") {
    //   period = ValueFormat.smallLettersFormat(filtersUserPage.durationFilter);
    //   startDate = UtilDateTime.getCurrentMonth(filtersUserPage.startDateTime);
    //   endDate = "";
    // } else if (filtersUserPage.durationFilter === "CURRENT_WEEK") {
    //   period = ValueFormat.smallLettersFormat(filtersUserPage.durationFilter);
    //   startDate = UtilDateTime.getCurrentWeek1(filtersUserPage.startDateTime);
    //   endDate = "";
    // } else if (filtersUserPage.durationFilter === "CURRENT_DAY") {
    //   period = ValueFormat.smallLettersFormat(filtersUserPage.durationFilter);
    //   startDate = UtilDateTime.getCurrentDay(filtersUserPage.startDateTime);
    //   endDate = "";
    // } else {
    //   period = ValueFormat.smallLettersFormat(filtersUserPage.durationFilter);
    //   startDate = filtersUserPage.startDateTime.split(" ")[0];
    //   endDate = filtersUserPage.endDateTime.split(" ")[0];
    // }

  

    

  const periodFormat = [""];

  function splitArrayIntoChunks(arr) {
    const chunkSize = 2;
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

 



 const originalData = apiDataRideList|| [
    {
                  rideName                          : "-",
                  status                            : "-",
                  drivingCategory                   : "-",
                  drivingBehaviourScore             : "-",
                  calculatedDrivingScore            : "-",
                  calculatedDrivingScoreValue       : "-",                   
                  drivingScore                      : "-",
                  rideId                            : "-",
                  userId                            : "-",
                  divisionId                        : "-",                     
                  ABCPoint                          : "-",                       
                  overallAnticipation               : "-",
                  overallSelfConfidence             : "-",
                  overallDrivingSkill               : "-",
                  totalKmSPoint                     : "-",
                  DSP                               : "-",
                  DSTP                              : "-",                
                  totalAccelerationCount            : "-",
                  totalBrakingCount                 : "-",
                  totalCorneringCount               : "-",
                  totalOverSpeedDuration            : "-",
                  totalMobileUseInAcceptedCount     : "-",           
                  totalMobileUseInAcceptedDuration  : "-",
                  totalMobileScreenScreenOnCount    : "-",           
                  totalMobileScreenScreenOnDuration : "-",
                  totalOverSpeedCount               : "-",           
                  tKiloMeter                        : "-",
                  tTravelTime                       : "-",
                  rideCategory                      : "-",
                  startTime                         : "-",
                  startTimeValue                    : "-",
                  startDateFormate                  : "-",
                  endTime                           : "-",
                  endDateFormate                    : "-",
                  batterylevelStart                 : "-",
                  batterylevelEnd                   : "-",
                  batterylevelTotalValue            : "-",
                  event                             : "-",
                  longEvent                         : "-",                
                  cppLevel                          : "-",
                  cppLevelColor                     : "-",           
                  mobileUsage                       : "-",           
                  day                               : "-",
                  night                             : "-",           
                  urbanKilometers                   : "-",
                  ruralKilometers                   : "-",
                  highwayKilometers                 : "-",
                  riskSlot10count                   : "-",
                  riskSlot10Distance                : "-",
                  riskSlot10Duration                : "-",
                  deviceMode                        : "-",
                  startLocationName                 : "-",
                  startDeviceCity                   : "-",
                  startDeviceState                  : "-",
                  lastLocationName                  : "-",
                  lastDeviceCity                    : "-",
                  lastDeviceState                   : "-",
                  insertMode                        : "-"
    },
  ];  
 

  const formatTime = (timeString) => {        
    const lastRideTime = timeString.split(" ")[1];   
    const today = new Date().toISOString().split('T')[0];  
    const dateTimeString = `${today}T${lastRideTime}`;
  
    const date = new Date(dateTimeString);
    if(timeString.length > 1) {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    }
    return "-";
  };

  const deviceNameHandle = (deviceName) => {

    if(deviceName == "PERSONAL_AUTO"){
      return "Personal"
    }else if(deviceName == "WORK_AUTO"){
      return "Work"
    }else{
      return "-"
    }
  }

  const deviceNameHandleApi = (deviceName) => {

    if(deviceName == "RIDE_CSV"){
      return "LSR"
    }else if(deviceName == "API_CALL"){
      return "CSR"      
    }else{
      return "-"
    }
  }

  const formatTimeNew = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  let rideDataList = [];
  let firstPageRideDataList = 9;
  let secondPageRideData = 9;

  for(let i=0; i < originalData.length;  i += secondPageRideData){
    if(i==0){
      rideDataList.push(originalData.slice(0, firstPageRideDataList));  
      i = firstPageRideDataList - secondPageRideData;    
    }else{
      rideDataList.push(originalData.slice(i, i + secondPageRideData));
    }
  }

  function capitalizeFirstLetter(string) {
    if (typeof string !== "string" || string.length === 0) {
        return ""; // Return an empty string for invalid input
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

  

  return (
    <React.Fragment>
      {/* {showLoader > 0 ? <LoaderPdf /> : ""} */}

      <PDFViewer
        style={{
          height: "96%",
          width: "100%",
          marginTop: "20px",
          marginBottom: "5px",
        }}
      >
        <Document style={{ marginTop: "15px", marginBottom: "5px" }}>
          {
          rideDataList.length > 0 ?
          rideDataList.map((chunk, pageIndex) => (
            <Page 
            key={pageIndex} 
            size="A2">
              <PdfHeader />
              <View style={{ padding: 10, paddingTop: 10 }}>
                 {/* <PdfHeading
                  heading={heading}
                  periodFormat={periodFormat}
                  reversedDateFormat={reversedDateFormat}
                  filtersUserPage={filtersUserPage}
                />  */}
              
                <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                  <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                  <Text style={{ fontSize: "12px", marginBottom: 5,fontFamily: "Helvetica-Bold"}}>
                   Ride List Report
                
                  </Text>                 

                  </View>
                
                </View>

                <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>

                <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  

                   <View style={{display:"flex",flexDirection:"row"}}>     

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center"}}>
                   Page
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                   {RideListPageNumber}
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",marginRight:"3px"}}>
                   of
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",marginRight:"3px",fontFamily: "Helvetica-Bold"}}>
                   {RideListTotalPageCount}
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",marginRight:"3px"}}>
                   |
                  </Text>

                  </View>   

                </View>

                
                <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  

                   <View style={{display:"flex",flexDirection:"row"}}>     

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center"}}>
                  Available Records:
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                   {totalRide}
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",marginRight:"3px"}}>
                   |
                  </Text>

                  </View>   

                </View>        

                <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  

                   <View style={{display:"flex",flexDirection:"row"}}>     

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center"}}>
                   Displayed Records:
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                   {originalData.length}
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",marginRight:"3px"}}>
                   |
                  </Text>

                  </View>   

                </View>                



                <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  

                   <View style={{display:"flex",flexDirection:"row"}}>     

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center"}}>
                  Ride Type:
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                   {RideListWorkFilter == "" ? "All" : ValueFormat.capitalizeFirstLetterAndFormat(RideListWorkFilter)}
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",marginRight:"3px"}}>
                   |
                  </Text>

                  </View>   

                </View>

                <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  

                   <View style={{display:"flex",flexDirection:"row"}}>     

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center"}}>
                  Search Text:
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                   {RideListSearchText ? RideListSearchText : "-"}
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",marginRight:"3px"}}>
                   |
                  </Text>

                  </View>   

                </View>

                <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  

                   <View style={{display:"flex",flexDirection:"row"}}>     

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center"}}>
                  Duration:
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                   {RideListfilter == "" ? "All" : RideListfilter == "CUSTOM_DURATION" ? `${UtilDateTime.formatDateIntSlash(RideListStartDateTime)} - ${UtilDateTime.formatDateIntSlash(RideListEndDateTime)}` : ValueFormat.capitalizeFirstLetterAndFormat(RideListfilter)}
                  </Text>

                 

                  </View>   

                </View>






                  
                
                </View>
                
                <View
                  style={{
                    marginBottom: 0,
                    paddingTop: 5,
                  }}
                >
                  <Text style={{ fontSize: "12px", marginBottom: 5,fontWeight:600}}>
                  Driving Score
                  </Text>

                  {/* <Text style={{ fontSize: 10, marginBottom: 7 }}>
                    {displayText.DRIVER_BEHAVIOUR_DESCRIPTION}
                  </Text>
                   */}
                  <View
                    style={{
                      // display:'flex',
                      flexDirection: "row",
                      backgroundColor: globalPDF.GREY_COLOR,
                      // justifyContent:"space-between",
                      height: "65px",
                      marginTop: 10,
                      
                    }}
                  >
                    <View 
                    style={{width:"260px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Ride Details</Text>

                                              </View>
                    </View>

                    

                    <View 
                    style={{width:"80px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving Score</Text>
                                              {/* <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Score</Text> */}
                                              </View>
                    </View>

                    <View 
                    style={{width:"60px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Risk</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"140px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving Risk</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Data</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"60px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Behaviour</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"140px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving Behaviour</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Data</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"100px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Crash</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Probability</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"100px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Acc/Brak/Cor</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Counts</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"90px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Over Speed</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Data</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"90px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Mobile</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Usage</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"100px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>High Risk</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Data</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"100px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Others</Text>

                                              </View>
                    </View>

                    <View 
                    style={{width:"100px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Context</Text>

                                              </View>
                    </View>


                  </View>

                  {chunk.map((apiData, ind) => (
                    <View  
                    key={ind}
                    >

                 
                  <View
                 
                    style={{
                      // display:'flex',
                      flexDirection: "row",
                      // justifyContent:"space-between",
                      height: "150px",
                      marginBottom:"0px",
                      border: "1px solid lightgrey",
                      // marginTop: 10,
                      
                    }}
                  >
                    
                    <View 
                    style={{width:"260px",height:"150px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center"}}
                    >
                      <View style={{display:"flex",flexDirection:"row"}}>
                      
                      <Text style={{fontSize: 10,marginLeft:"5px",fontWeight:700,color:"#5156BE",fontFamily: "Helvetica-Bold",marginTop:"2px"}}>
                      {apiData.rideName} 
                        </Text>
                      <Text style={{fontSize: 10,marginLeft:"5px",fontWeight:700,marginTop:"2px"}}>|</Text>
                     
                      <View style={{backgroundColor:"#D7EC23",marginLeft:"5px",padding:2}}>
                      <Text style={{fontSize: 10}}>
                      {deviceNameHandle(apiData.deviceMode)}
                        </Text>
                      </View>

                      <Text style={{fontSize: 10,marginLeft:"5px",fontWeight:700,marginTop:"2px"}}>|</Text>

                      <View style={{backgroundColor:"#D7EC23",marginLeft:"5px",padding:2}}>
                      <Text style={{fontSize: 10}}>
                      {deviceNameHandleApi(apiData.insertMode)}
                        </Text>
                      </View>
                     
                      </View>

                      <View style={{display:"flex",flexDirection:"row",marginTop:"10px"}}>                         
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>{UtilDateTime.formatDateInt(apiData.startTimeValue)} | {formatTimeNew(apiData.startTime)}</Text>                          
                     </View>                     
                        
                        <View style={{display:"flex",flexDirection:"row",marginTop:"10px"}}>
                        <Image src={LocationIcon} style={{ width: "15px", height: "15px" }} />
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px"}}>
                        {apiData.tKiloMeter + " Kms"} | 

{ 
  parseFloat(apiData.tTravelTime) < 60 
    ? ` ${parseFloat(apiData.tTravelTime).toFixed(2)} Mins` 
    : ` ${(parseFloat(apiData.tTravelTime) / 60).toFixed(2)} Hours` 
}
                          </Text>
                        </View>

                        <View style={{display:"flex",flexDirection:"row",marginTop:"10px"}}>
                        <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <Image src={AddressIcon} style={{ width: "15px", height: "15px" }} />
                        </View>
                        <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px"}}>
                        {apiData.startLocationName}
                          </Text>
                          <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px"}}>
                         {apiData.startDeviceCity} {apiData.startDeviceState}
                          </Text>
                          </View>
                        </View>

                        <View style={{display:"flex",flexDirection:"row",marginTop:"10px"}}>
                        <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <Image src={LocationIconNew} style={{ width: "15px", height: "15px" }} />
                        </View>
                        <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px"}}>
                        {apiData.lastLocationName}
                          </Text>
                          <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px"}}>
                        {apiData.lastDeviceCity} {apiData.lastDeviceState}
                          </Text>
                          </View>
                        </View>
                        

                      
                      
                      

                    
                    </View>

                    <View  style={{width:"80px",height:"150px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>
                          {apiData.calculatedDrivingScore}
                          </Text>
                        </View>
                        <View style={{display:"flex",flexDirection:"row",marginTop:"5px",justifyContent:"center"}}>
                        <Image src={steeringWheel} style={{ width: "15px", height: "15px" }} />
                        <Text style={{fontSize: 9,marginLeft:"2px",marginTop:"3px"}}>
                        {apiData.drivingCategory}
                          </Text>
                        </View>                 
                    </View>


                    <View  style={{width:"60px",height:"150px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>
                          {ValueFormat.formatDecimalIfRounded(apiData.drivingScore)}
                                    </Text>
                        </View>                 
                    </View>

                    <View 
                    style={{width:"140px",height:"150px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",}}
                    >           
                   
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={AnticipationIcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Anticipation</Text>
                          <Text style={{fontSize: 9,marginLeft:"26px"}}>
                          {" - "}
                            {`${ValueFormat.formatDecimalIfRounded(
                                                                    apiData.overallAnticipation
                                                                  )}`}
                                      </Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={SelfConfidenceIcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Self Confidence</Text>
                          <Text style={{fontSize: 9,marginLeft:"10px"}}>
                            {" - "}
                                                                  {ValueFormat.formatDecimalIfRounded(
                                                                    apiData.overallSelfConfidence
                                                                  )}
                                      </Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={DrivingSkillcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Driving Skill</Text>
                          <Text style={{fontSize: 9,marginLeft:"26px"}}> 
                            {" - "}
                                                                  {ValueFormat.formatDecimalIfRounded(
                                                                    apiData.overallDrivingSkill
                                                                  )}
                                      </Text>
                          </View>                                
                    </View>



                    <View  style={{width:"60px",height:"150px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>
                        {apiData.drivingBehaviourScore}
                          </Text>
                        </View>                 
                    </View>

                    <View 
                    style={{width:"140px",height:"150px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center"}}
                    >           
                    {/* <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                      <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>{apiData.drivingBehaviourScore}</Text>
                      </View>      */}
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={DrivingStylelcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Driving Style</Text>
                          <Text style={{fontSize: 9,marginLeft:"22px"}}>
                            {" - "}
                                                                  {`${ValueFormat.formatDecimalIfRounded(
                                                                    apiData.DSTP
                                                                  )}`}
                                      </Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={DrivingStatelcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Driving State</Text>
                          <Text style={{fontSize: 9,marginLeft:"21px"}}>
                            {" - "}
                                                                  {ValueFormat.formatDecimalIfRounded(
                                                                    apiData.DSP
                                                                  )}
                                      </Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={MobileUsagelcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Mobile Usage</Text>
                          <Text style={{fontSize: 9,marginLeft:"19px"}}>
                          {" - "}
                          {apiData.mobileUsage}
                            </Text>
                          </View>     
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={OverSpeedlcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Over Speed</Text>
                          <Text style={{fontSize: 9,marginLeft:"26px"}}>
                            {" - "}
                                                                  {ValueFormat.formatDecimalIfRounded(
                                                                    apiData.totalKmSPoint
                                                                  )}
                                      </Text>
                          </View>     
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={Abclcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Acc/Brak/Cor</Text>
                          <Text style={{fontSize: 9,marginLeft:"26px"}}>
                           {" - "}
                                                                 {ValueFormat.formatDecimalIfRounded(
                                                                   apiData.ABCPoint
                                                                 )}
                                      </Text>
                          </View>                                
                    </View>

                    <View  style={{width:"100px",height:"150px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       
                    <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                      <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>{apiData.cppLevel}</Text>
                      </View>   

                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center",marginTop:"8px"}}>
                      
                        <Text style={{fontSize:"10px"}}>L0 is Safe</Text>
                        </View>  
                        <View style={{display:"flex",flexDirection:"row",justifyContent:"center",marginTop:"8px"}}>
                      
                        <Text style={{fontSize:"10px"}}>L6 is Unsafe</Text>
                        </View>                 
                    </View>

                    <View 
                    style={{width:"100px",height:"150px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}
                    >              
                          <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                            <View style={{width:"25%"}}>
                          <Image src={AccIcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View >
                          <Text style={{fontSize: 9,marginTop:"2px"}}>Acc</Text>
                          </View>
                          <View >
                          <Text style={{fontSize: 9,marginLeft:"10px",marginTop:"2px"}}> 
                          {" - "}
                          {apiData.totalAccelerationCount}
                            </Text>
                            </View>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px",justifyContent:"center"}}>
                          <View style={{width:"25%"}}>
                          <Image src={BrakIcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View>
                          <Text style={{fontSize: 9,marginTop:"2px"}}>Brak</Text>
                          </View>
                          <View>
                          <Text style={{fontSize: 9,marginLeft:"10px",marginTop:"2px"}}>
                          {" - "}
                          {apiData.totalBrakingCount}
                            </Text>
                            </View>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px",justifyContent:"center"}}>
                          <View style={{width:"25%"}}>
                          <Image src={CorIcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View>
                          <Text style={{fontSize: 9,marginTop:"2px"}}>Cor
                          </Text>
                          </View>
                          <View>
                          <Text style={{fontSize: 9,marginLeft:"10px",marginTop:"2px"}}>
                          {" - "}
                          {apiData.totalCorneringCount}
                            </Text>
                            </View>
                          </View>     
                                                      
                    </View>


                    <View 
                    style={{width:"90px",height:"150px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}
                    >              
                          <View style={{display:"flex",flexDirection:"row",}}>
                            <View style={{width:"25px"}}>
                          <Image src={Countlcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View>
                          <Text style={{fontSize: 9,marginTop:"3px",width:"48px"}}>
                          {apiData.totalOverSpeedCount}{" "}{"Count"}
                            </Text>
                            </View>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"8px"}}>
                          <View style={{width:"10px"}}>
                          <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View>
                          <Text style={{fontSize: 9,marginLeft:"15px",marginTop:"2px"}}>
                          { 
                                        parseFloat(apiData.totalOverSpeedDuration) < 60 
                                          ? `${parseFloat(apiData.totalOverSpeedDuration).toFixed(2)} Mins` 
                                          : `${(parseFloat(apiData.totalOverSpeedDuration) / 60).toFixed(2)} Hours` 
                                      }
                            </Text>
                            </View>
                         
                          </View>
                          
                                                      
                    </View>

                    <View 
                    style={{width:"90px",height:"150px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}
                    >              
                          <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                            <View style={{width:"20px"}}>
                          <Image src={ScreenCountlcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View style={{width:"48px"}}>
                          <Text style={{fontSize: 9,marginTop:"2px"}}>
                          {apiData.totalMobileScreenScreenOnCount}{" "}{"Count"}
                            </Text>
                            </View>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"8px",justifyContent:"center"}}>
                          <View style={{width:"20px"}}>
                          <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View style={{width:"48px"}}>
                          <Text style={{fontSize: 9,marginTop:"2px"}}>
                          { 
                                        parseFloat(apiData.totalMobileScreenScreenOnDuration) < 60 
                                          ? `${parseFloat(apiData.totalMobileScreenScreenOnDuration).toFixed(2)} Mins` 
                                          : `${(parseFloat(apiData.totalMobileScreenScreenOnDuration) / 60).toFixed(2)} Hours` 
                                      }
                            </Text>
                            </View>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"8x",justifyContent:"center"}}>
                          <View style={{width:"20px"}}>
                          <Image src={CallCountlcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View style={{width:"48px"}}>
                          <Text style={{fontSize: 9,marginTop:"2px"}}>
                          {apiData.totalMobileUseInAcceptedCount}{" "}{"Count"}
                            </Text>
                            </View>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"8px",justifyContent:"center"}}>
                          <View style={{width:"20px"}}>
                          <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View style={{width:"48px"}}>
                          <Text style={{fontSize: 9,marginTop:"2px"}}>
                          { 
                                        parseFloat(apiData.totalMobileUseInAcceptedDuration) < 60 
                                          ? `${parseFloat(apiData.totalMobileUseInAcceptedDuration).toFixed(2)} Mins` 
                                          : `${(parseFloat(apiData.totalMobileUseInAcceptedDuration) / 60).toFixed(2)} Hours` 
                                      }
                            </Text>
                            </View>
                         
                          </View>
                          
                                                      
                    </View>

                    <View 
                    style={{width:"100px",height:"150px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}
                    >              
                          <View style={{display:"flex",flexDirection:"row"}}>
                          <Image src={Countlcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>
                          {apiData.riskSlot10count}{" "}{"Count"}
                            </Text>
                         
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={LocationIcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>
                          {apiData.riskSlot10Distance}{" "}{"Kms"}
                            </Text>
                         
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>
                          {apiData.riskSlot10Duration}{" "}{"Mins"}
                            </Text>
                         
                          </View>

                          
                          
                                                      
                    </View>

                    <View 
                    style={{width:"100px",height:"150px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}
                    >              
                          <View style={{display:"flex",flexDirection:"row",}}>
                          <Image src={RdIcon} style={{ width: "15px", height: "15px" }} />

                          <View>
                            <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>
                            Start Time
                            </Text>
                            <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"4px"}}>
                            {`${UtilDateTime.formatDateTimeNew(apiData.startTime)}`}
                            </Text>
                            <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"4px"}}>
                            {`${apiData.startDateFormate}`}
                            </Text>

                            <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"5px"}}>
                            End Time
                            </Text>
                            <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"4px"}}>
                            {`${UtilDateTime.formatDateTimeNew(apiData.endTime)}`}
                            </Text>
                            <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"4px"}}>
                            {`${apiData.endDateFormate}`}
                            </Text>

                          </View>
                          
                          
                         
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={BattryIcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>
                          {apiData.batterylevelTotalValue < 0 ? `Battery Consumption | ${Math.abs(apiData.batterylevelTotalValue)}% ` : apiData.batterylevelTotalValue == 0 ? "No Battery Consumption" : "Battery Charged" }
                            </Text>
                         
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={JvIcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>
                            {/* {apiData.totalHighRiskCount}{" "}{"Count"} */}
                            </Text>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"3px",justifyContent:"center",marginBottom:"3px"}}>
                          
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"1px"}}>
                          {apiData.event}
                            </Text>
                         
                          </View>

                          
                          
                                                      
                    </View>

                    <View 
                    style={{width:"100px",height:"150px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}
                    >              
                          <View style={{display:"flex",flexDirection:"row"}}>
                          {/* <Image src={Countlcon} style={{ width: "15px", height: "15px" }} /> */}
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>
                          U{" - "}{apiData.urbanKilometers}{" "}{"Kms"}
                            </Text>
                         
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          {/* <Image src={LocationIcon} style={{ width: "15px", height: "15px" }} /> */}
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>
                          R{" - "}{apiData.ruralKilometers}{" "}{"Kms"}
                            </Text>
                         
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          {/* <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} /> */}
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>
                          H{" - "}{apiData.highwayKilometers}{" "}{"Kms"}
                            </Text>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          {/* <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} /> */}
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>
                          D{" - "}{apiData.day}{" "}{"%"}
                            </Text>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          {/* <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} /> */}
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>
                          N{" - "}{apiData.night}{" "}{"%"}
                            </Text>
                         
                          </View>

                          
                          
                                                      
                    </View>


                    




                  </View>
                  </View>
                ))}

                 
                 
                </View>               

              

            <View>           


              
            </View>    

            

                


                  


            

              </View>

              <PdfFooterV1
                pageNumber={pageIndex + 1}
                totalPages={rideDataList.length}
                reversedDateFormat={formattedDate}
              />
            </Page>
          )) : (

            <Page 
                        
                        size="A2">
                          <PdfHeader />
                          <View style={{ padding: 10, paddingTop: 10 }}>
                 {/* <PdfHeading
                  heading={heading}
                  periodFormat={periodFormat}
                  reversedDateFormat={reversedDateFormat}
                  filtersUserPage={filtersUserPage}
                />  */}
              
                <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                  <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                  <Text style={{ fontSize: "12px", marginBottom: 5,fontFamily: "Helvetica-Bold"}}>
                   Ride List Report
                
                  </Text>                 

                  </View>
                
                </View>

                <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>

                <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  

                   <View style={{display:"flex",flexDirection:"row"}}>     

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center"}}>
                   Page
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                   {RideListPageNumber}
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",marginRight:"3px"}}>
                   of
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",marginRight:"3px",fontFamily: "Helvetica-Bold"}}>
                   {RideListTotalPageCount}
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",marginRight:"3px"}}>
                   |
                  </Text>

                  </View>   

                </View>

                
                <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  

                   <View style={{display:"flex",flexDirection:"row"}}>     

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center"}}>
                  Available Records:
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                   {totalRide}
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",marginRight:"3px"}}>
                   |
                  </Text>

                  </View>   

                </View>        

                <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  

                   <View style={{display:"flex",flexDirection:"row"}}>     

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center"}}>
                   Displayed Records:
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                   {originalData.length}
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",marginRight:"3px"}}>
                   |
                  </Text>

                  </View>   

                </View>                



                <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  

                   <View style={{display:"flex",flexDirection:"row"}}>     

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center"}}>
                  Ride Type:
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                   {RideListWorkFilter == "" ? "All" : ValueFormat.capitalizeFirstLetterAndFormat(RideListWorkFilter)}
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",marginRight:"3px"}}>
                   |
                  </Text>

                  </View>   

                </View>

                <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  

                   <View style={{display:"flex",flexDirection:"row"}}>     

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center"}}>
                  Search Text:
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                   {RideListSearchText ? RideListSearchText : "-"}
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",marginRight:"3px"}}>
                   |
                  </Text>

                  </View>   

                </View>

                <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  

                   <View style={{display:"flex",flexDirection:"row"}}>     

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center"}}>
                  Duration:
                  </Text>

                  <Text style={{ fontSize: "9px", marginBottom: 5,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                   {RideListfilter == "" ? "All" : RideListfilter == "CUSTOM_DURATION" ? `${UtilDateTime.formatDateIntSlash(RideListStartDateTime)} - ${UtilDateTime.formatDateIntSlash(RideListEndDateTime)}` : ValueFormat.capitalizeFirstLetterAndFormat(RideListfilter)}
                  </Text>

                 

                  </View>   

                </View>






                  
                
                </View>
                
                <View
                  style={{
                    marginBottom: 0,
                    paddingTop: 5,
                  }}
                >
                  <Text style={{ fontSize: "12px", marginBottom: 5,fontWeight:600}}>
                  Driving Score
                  </Text>

                  {/* <Text style={{ fontSize: 10, marginBottom: 7 }}>
                    {displayText.DRIVER_BEHAVIOUR_DESCRIPTION}
                  </Text>
                   */}
                  <View
                    style={{
                      // display:'flex',
                      flexDirection: "row",
                      backgroundColor: globalPDF.GREY_COLOR,
                      // justifyContent:"space-between",
                      height: "65px",
                      marginTop: 10,
                      
                    }}
                  >
                    <View 
                    style={{width:"260px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Ride Details</Text>

                                              </View>
                    </View>

                    

                    <View 
                    style={{width:"80px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving Score</Text>
                                              {/* <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Score</Text> */}
                                              </View>
                    </View>

                    <View 
                    style={{width:"60px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Risk</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"140px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving Risk</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Data</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"60px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Behaviour</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"140px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving Behaviour</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Data</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"100px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Crash</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Probability</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"100px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Acc/Brak/Cor</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Counts</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"90px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Over Speed</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Data</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"90px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Mobile</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Usage</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"100px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>High Risk</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Data</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"100px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Others</Text>

                                              </View>
                    </View>

                    <View 
                    style={{width:"100px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Context</Text>

                                              </View>
                    </View>


                  </View>

              
                    <View  
                  
                    >

                 
                  <View
                 
                    style={{
                      // display:'flex',
                      flexDirection: "row",
                      // justifyContent:"space-between",
                      height: "150px",
                      marginBottom:"0px",
                      border: "1px solid lightgrey",
                      // marginTop: 10,
                      
                    }}
                  >                    




                  </View>
                  </View>
              

                 
                 
                </View>               

              

            <View>           


              
            </View>    

            

                


                  


            

              </View>
            
                          <PdfFooterV1
                            pageNumber={1}
                            totalPages={1}
                            reversedDateFormat={formattedDate}
                          />
                        </Page>

          )}

          
         
        </Document>
      </PDFViewer>
    </React.Fragment>
  );
};

export default RideListAllSummaryPdf;
