import React, { useEffect } from "react";
import ReactPDF, {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Rect,
  Svg,
  Line,
} from "@react-pdf/renderer";
import RuduLogo from "../../../assets/images/logo/motivAiLogoPng.png";
import MotivaiLogo from "../../../assets/images/logo.motivAI.png";
import { useState } from "react";
import LoaderPdf from "../../Common/loaderPdf";
import {
  SC_USERNAME,
  SC_LOGIN_USER_FIRST_NAME,
  SC_COMPANY_NAME,
  SC_PROFILE_OPEN,
  SC_USER_LOGIN_TYPE,
  SC_DIVISION_NAME,
  SC_REGISTRATION_DATE,
  SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant";
import { number } from "echarts";
import ValueFormat from "../../../util/ValueFormat";
import PdfHeaderV1 from "../Components/pdfHeaderV1";
import PdfFooterV1 from "../Components/pdfFooterV1";
import { useLocation } from "react-router-dom";
import PdfHeading from "../Components/pdfHeading";
import { globalPDF } from "../../../assets/css/commonCssTextForPdf";
import UtilDateTime from "../../../util/UtilDateTime";

import LocationIcon from "../../../assets/images/adminDashboard/pdf/locationIconPdf.png";
import MinsIcon from "../../../assets/images/adminDashboard/pdf/minsIconPdf.png";
import AnticipationIcon from "../../../assets/images/adminDashboard/pdf/AnticipationIconPdf.png";
import SelfConfidenceIcon from "../../../assets/images/adminDashboard/pdf/SelfconfidenceIconPdf.png";
import DrivingSkillcon from "../../../assets/images/adminDashboard/pdf/DrivingskillIconPdf.png";
import DrivingStylelcon from "../../../assets/images/adminDashboard/pdf/DrivingstyleIcon.png";
import DrivingStatelcon from "../../../assets/images/adminDashboard/pdf/DrivingStateIcon.png";
import MobileUsagelcon from "../../../assets/images/adminDashboard/pdf/MobileUsageIcon.png";
import OverSpeedlcon from "../../../assets/images/adminDashboard/pdf/OverSpeedIcon.png";
import Abclcon from "../../../assets/images/adminDashboard/pdf/AbcIcon.png";
import Countlcon from "../../../assets/images/adminDashboard/pdf/countIcon.png";
import CallCountlcon from "../../../assets/images/adminDashboard/pdf/CallcountIcon.png";
import ScreenCountlcon from "../../../assets/images/adminDashboard/pdf/ScreencountIcon.png";
import steeringWheel from "../../../assets/images/adminDashboard/pdf/steeringWheelIcon.png";
import AddressIcon from "../../../assets/images/pdf/RideList/addressIcon.png";
import LocationIconNew from "../../../assets/images/pdf/RideList/locationIcon.png";
import AccIcon from "../../../assets/images/pdf/RideList/accIcon.png";
import BrakIcon from "../../../assets/images/pdf/RideList/brakIcon.png";
import CorIcon from "../../../assets/images/pdf/RideList/corIcon.png";
import RdIcon from "../../../assets/images/pdf/RideList/rdIcon.png";
import BattryIcon from "../../../assets/images/pdf/RideList/battryIcon.png";
import JvIcon from "../../../assets/images/pdf/RideList/jvIcon.png";

// Create Document Component

const styles = StyleSheet.create({
  gridContainer: {
    marginBottom: 0,
  },
  rowContainer: {
    flexDirection: "row", // Arrange items horizontally
    justifyContent: "space-between",
    gap: 10,
    marginBottom: 10, // Space between rows
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    textAlign: "left",
    border: "1px solid lightgrey",
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: "left",
    padding: 10,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  image: {
    width: 30,
    height: 30,
    resizeMode: "contain",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "70%",
  },
  title: {
    fontSize: 9,
    fontWeight: "bold",
  },
  dataContainer: {},
  dataText: {
    fontSize: 12,
    color: "#555",
  },

  page: {
    padding: 30,
    paddingTop: 10,
  },
  header: {
    fontSize: 16,
    marginBottom: 15,
    marginTop: 20,
  },
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 15,
  },
  // Cell styles with fixed width, height, column gap, and row gap
  cell: {
    width: "100%", // Fixed width for columns (8 columns)
    height: 40, // Fixed height for rows
    marginRight: 5, // Gap between columns
    border: "1px solid lightgrey",
    borderTop: 0,
    fontSize: 9,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  cellHeader: {
    width: "100%", // Fixed width for columns (8 columns)
    height: 40, // Fixed height for rows
    // padding: 5,
    marginRight: 5, // Gap between columns
    // marginBottom: 5, // Gap between rows
    border: "1px solid lightgrey",
    fontSize: 9,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: globalPDF.GREY_COLOR,
  },

  row: {
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },

  headerText: {
    fontFamily: "Helvetica-Bold",
    fontSize: 9,
    textAlign: "center",
  },
  headerTextDNA: {
    fontFamily: "Helvetica-Bold",
    fontSize: 9,
    textAlign: "center",
    color: "red",
  },
  mapText: {
    fontSize: 9,
    textAlign: "center",
  },
});

export default function RideDetailPdfNew({
  rideDetailsSummary,
  rideNameData,
  rideDetailsRiskDataList,
  rideDetailsDistributionData,
  rideDetailsDriverStateList,
  rideDetailsDriverStyleList
}) {

  console.log(rideDetailsDriverStyleList,"rideDetailsDriverStyleList")
  const fontBold = { fontFamily: "Helvetica-Bold" };

  let dataData = [{value:rideDetailsDistributionData?.data},{label:rideDetailsDistributionData?.xaxis}]
  

  const pathname = useLocation();
  const [showLoader, setShowLoader] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const companyName = localStorage.getItem(SC_COMPANY_NAME);
  const firstName = ValueFormat.capitalizeFirstLetter(
    localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)
  );
  const userStatus = localStorage.getItem(SC_PROFILE_OPEN);
  const userName = localStorage.getItem(SC_USERNAME);
  const loginType = localStorage.getItem(SC_USER_LOGIN_TYPE);
  const unitName = localStorage.getItem(SC_DIVISION_NAME);
  const createdDate = localStorage.getItem(SC_REGISTRATION_DATE);

  const heading = "Ride Detail Dashboard";
  var currentDate = new Date();

  const originalData = rideDetailsSummary || [
    {
      rideName: "-",
      status: "-",
      drivingCategory: "-",
      drivingBehaviourScore: "-",
      calculatedDrivingScore: "-",
      calculatedDrivingScoreValue: "-",
      drivingScore: "-",
      rideId: "-",
      userId: "-",
      divisionId: "-",
      ABCPoint: "-",
      overallAnticipation: "-",
      overallSelfConfidence: "-",
      overallDrivingSkill: "-",
      totalKmSPoint: "-",
      DSP: "-",
      DSTP: "-",
      totalAccelerationCount: "-",
      totalBrakingCount: "-",
      totalCorneringCount: "-",
      totalOverSpeedDuration: "-",
      totalMobileUseInAcceptedCount: "-",
      totalMobileUseInAcceptedDuration: "-",
      totalMobileScreenScreenOnCount: "-",
      totalMobileScreenScreenOnDuration: "-",
      totalOverSpeedCount: "-",
      tKiloMeter: "-",
      tTravelTime: "-",
      rideCategory: "-",
      startTime: "-",
      startTimeValue: "-",
      startDateFormate: "-",
      endTime: "-",
      endDateFormate: "-",
      batterylevelStart: "-",
      batterylevelEnd: "-",
      batterylevelTotalValue: "-",
      event: "-",
      longEvent: "-",
      cppLevel: "-",
      cppLevelColor: "-",
      mobileUsage: "-",
      day: "-",
      night: "-",
      urbanKilometers: "-",
      ruralKilometers: "-",
      highwayKilometers: "-",
      riskSlot10count: "-",
      riskSlot10Distance: "-",
      riskSlot10Duration: "-",
      deviceMode: "-",
      startLocationName: "-",
      startDeviceCity: "-",
      startDeviceState: "-",
      lastLocationName: "-",
      lastDeviceCity: "-",
      lastDeviceState: "-",
    },
  ];

  const deviceNameHandle = (deviceName) => {
    if (deviceName == "PERSONAL_AUTO") {
      return "Personal";
    } else if (deviceName == "WORK_AUTO") {
      return "Work";
    } else {
      return "-";
    }
  };

  const formatTimeNew = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const chunkArray = (arr, chunkSize) => {
    if (!Array.isArray(arr) || chunkSize <= 0) return [];
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const allWidgets = (rideDetailsRiskDataList || []).flat();
  const limitedWidgets = allWidgets.slice(0, 24);

  // Chunk the 24 items into rows of 8
  const rows = chunkArray(limitedWidgets, 4);

  const ProgressBar = ({ progress }) => {
    console.log(progress,"gwgjhwegwegwgdtwe")
    
    const lineWidth = 350; 
    const progressWidth = (progress?.value / 100) * lineWidth; 
  
    console.log(progressWidth,"progressWidth")
    return (
      <View style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center" }}>
        <Svg width="100%" height="20" style={{ marginTop: 10 }}>
          {/* Background bar */}
          <Rect
            x="0"
            y="0"
            width={lineWidth}
            height="10"
            fill="#d9d9d9"
          />
          {/* Progress bar */}
          <Rect
            x="0"
            y="0"
            width={progressWidth}
            height="10"
            fill={progress?.index >= 3 ? "#19543E" : (progress?.index >= 1 && progress?.index <= 2 ) ? "#ffbf00" : "#FD0100"}
          />
          {/* Percentage text */}
          
        </Svg>
      </View>
    );
  };

  

  const combinedArray = rideDetailsDistributionData?.data.map((item, index) => {
    const label = rideDetailsDistributionData?.xaxis[index];
    console.log(label,"labellabellabel")
    
    
    const match = label.match(/\((\d+)\)/);
    const labelNumber = match ? match[1] : null;
  
    return {
      value: parseFloat(item.value),
      customData: item.customData,
      label: label,
      labelNumber: labelNumber ? parseInt(labelNumber, 10) : null,
      index:index
    };
  });

 

  return (
    <React.Fragment>
      {/* {showLoader > 0 ? <LoaderPdf /> : ""} */}

      <PDFViewer
        style={{
          height: "96%",
          width: "100%",
          marginTop: "20px",
          marginBottom: "5px",
        }}
      >
        <Document style={{ marginTop: "15px", marginBottom: "5px" }}>
          <Page
            size={"A2"}
            // pageNumber={pageNumber}
          >
            <PdfHeaderV1 />
            <View style={{ padding: 10, paddingTop: 20 }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: "12px",
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  }}
                >
                  Ride Details Report
                </Text>
              </View>

              <View
                style={{
                  marginBottom: 0,
                  paddingTop: 10,
                }}
              >
                {/* Ride Details Summary Start */}

                <Text
                  style={{ fontSize: "12px", marginBottom: 5, fontWeight: 600 }}
                >
                  {rideNameData}
                </Text>

                <View
                  style={{
                    flexDirection: "row",
                    backgroundColor: globalPDF.GREY_COLOR,
                    height: "65px",
                    marginTop: 15,
                  }}
                >
                  <View
                    style={{
                      width: "260px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        User Details
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "80px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Driving Score
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "60px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Driving
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Risk
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "140px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Driving Risk
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Data
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "60px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Driving
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Behaviour
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "140px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Driving Behaviour
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Data
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "100px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Crash
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Probability
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "100px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Acc/Brak/Cor
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Counts
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "90px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Over Speed
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Data
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "90px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Mobile
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Usage
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "100px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        High Risk
                      </Text>
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Data
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "100px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Others
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "100px",
                      border: "1px solid lightgrey",
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderLeft: "0px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontSize: 10, fontFamily: "Helvetica-Bold" }}
                      >
                        Context
                      </Text>
                    </View>
                  </View>
                </View>

                {originalData.map((apiData, ind) => (
                  <View key={ind}>
                    <View
                      style={{
                        flexDirection: "row",
                        height: "171px",
                        marginBottom: "0px",
                        border: "1px solid lightgrey",
                      }}
                    >
                      <View
                        style={{
                          width: "260px",
                          height: "170px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <Text
                            style={{
                              fontSize: 10,
                              marginLeft: "5px",
                              fontWeight: 700,
                              color: "#5156BE",
                              fontFamily: "Helvetica-Bold",
                              marginTop: "2px",
                            }}
                          >
                            {apiData.rideName}
                          </Text>
                          <Text
                            style={{
                              fontSize: 10,
                              marginLeft: "5px",
                              fontWeight: 700,
                              marginTop: "2px",
                            }}
                          >
                            |
                          </Text>

                          <View
                            style={{
                              backgroundColor: "#D7EC23",
                              marginLeft: "5px",
                              padding: 2,
                            }}
                          >
                            <Text style={{ fontSize: 10 }}>
                              {deviceNameHandle(apiData.deviceMode)}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            {UtilDateTime.formatDateInt(apiData.startTimeValue)}{" "}
                            | {formatTimeNew(apiData.startTime)}
                          </Text>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <Image
                            src={LocationIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "5px",
                              marginTop: "3px",
                            }}
                          >
                            {apiData.tKiloMeter + " Kms"} |
                            {parseFloat(apiData.tTravelTime) < 60
                              ? ` ${parseFloat(apiData.tTravelTime).toFixed(
                                  2
                                )} Mins`
                              : ` ${(
                                  parseFloat(apiData.tTravelTime) / 60
                                ).toFixed(2)} Hours`}
                          </Text>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <Image
                            src={AddressIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "5px",
                              marginTop: "3px",
                            }}
                          >
                            {apiData.startLocationName}{" "}
                            {apiData.startDeviceCity} {apiData.startDeviceState}
                          </Text>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                          }}
                        >
                          <Image
                            src={LocationIconNew}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "5px",
                              marginTop: "3px",
                            }}
                          >
                            {apiData.lastLocationName} {apiData.lastDeviceCity}{" "}
                            {apiData.lastDeviceState}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "80px",
                          height: "170px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "12px",
                              fontWeight: 700,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {apiData.calculatedDrivingScore}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                            justifyContent: "center",
                          }}
                        >
                          <Image
                            src={steeringWheel}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "2px",
                              marginTop: "3px",
                            }}
                          >
                            {apiData.drivingCategory}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "60px",
                          height: "170px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "12px",
                              fontWeight: 700,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {ValueFormat.formatDecimalIfRounded(
                              apiData.drivingScore
                            )}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "140px",
                          height: "170px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={AnticipationIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Anticipation
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "26px" }}>
                            {" - "}
                            {`${ValueFormat.formatDecimalIfRounded(
                              apiData.overallAnticipation
                            )}`}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={SelfConfidenceIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Self Confidence
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "10px" }}>
                            {" - "}
                            {ValueFormat.formatDecimalIfRounded(
                              apiData.overallSelfConfidence
                            )}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={DrivingSkillcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Driving Skill
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "26px" }}>
                            {" - "}
                            {ValueFormat.formatDecimalIfRounded(
                              apiData.overallDrivingSkill
                            )}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "60px",
                          height: "170px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "12px",
                              fontWeight: 700,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {apiData.drivingBehaviourScore}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "140px",
                          height: "170px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={DrivingStylelcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Driving Style
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "22px" }}>
                            {" - "}
                            {`${ValueFormat.formatDecimalIfRounded(
                              apiData.DSTP
                            )}`}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={DrivingStatelcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Driving State
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "21px" }}>
                            {" - "}
                            {ValueFormat.formatDecimalIfRounded(apiData.DSP)}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={MobileUsagelcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Mobile Usage
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "19px" }}>
                            {" - "}
                            {apiData.mobileUsage}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={OverSpeedlcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Over Speed
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "26px" }}>
                            {" - "}
                            {ValueFormat.formatDecimalIfRounded(
                              apiData.totalKmSPoint
                            )}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={Abclcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text style={{ fontSize: 9, marginLeft: "5px" }}>
                            Acc/Brak/Cor
                          </Text>
                          <Text style={{ fontSize: 9, marginLeft: "26px" }}>
                            {" - "}
                            {ValueFormat.formatDecimalIfRounded(
                              apiData.ABCPoint
                            )}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "100px",
                          height: "170px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: "12px",
                              fontWeight: 700,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {apiData.cppLevel}
                          </Text>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: "8px",
                          }}
                        >
                          <Text style={{ fontSize: "10px" }}>L0 is Safe</Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: "8px",
                          }}
                        >
                          <Text style={{ fontSize: "10px" }}>L6 is Unsafe</Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "100px",
                          height: "170px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ width: "25%" }}>
                            <Image
                              src={AccIcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View>
                            <Text style={{ fontSize: 9, marginTop: "2px" }}>
                              Acc
                            </Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "10px",
                                marginTop: "2px",
                              }}
                            >
                              {" - "}
                              {apiData.totalAccelerationCount}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ width: "25%" }}>
                            <Image
                              src={BrakIcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View>
                            <Text style={{ fontSize: 9, marginTop: "2px" }}>
                              Brak
                            </Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "10px",
                                marginTop: "2px",
                              }}
                            >
                              {" - "}
                              {apiData.totalBrakingCount}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ width: "25%" }}>
                            <Image
                              src={CorIcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View>
                            <Text style={{ fontSize: 9, marginTop: "2px" }}>
                              Cor
                            </Text>
                          </View>
                          <View>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "10px",
                                marginTop: "2px",
                              }}
                            >
                              {" - "}
                              {apiData.totalCorneringCount}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "90px",
                          height: "170px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <View style={{ width: "25px" }}>
                            <Image
                              src={Countlcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View>
                            <Text
                              style={{
                                fontSize: 9,
                                marginTop: "3px",
                                width: "48px",
                              }}
                            >
                              {apiData.totalOverSpeedCount} {"Count"}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "8px",
                          }}
                        >
                          <View style={{ width: "10px" }}>
                            <Image
                              src={MinsIcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "15px",
                                marginTop: "2px",
                              }}
                            >
                              {parseFloat(apiData.totalOverSpeedDuration) < 60
                                ? `${parseFloat(
                                    apiData.totalOverSpeedDuration
                                  ).toFixed(2)} Mins`
                                : `${(
                                    parseFloat(apiData.totalOverSpeedDuration) /
                                    60
                                  ).toFixed(2)} Hours`}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "90px",
                          height: "170px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ width: "20px" }}>
                            <Image
                              src={ScreenCountlcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View style={{ width: "48px" }}>
                            <Text style={{ fontSize: 9, marginTop: "2px" }}>
                              {apiData.totalMobileScreenScreenOnCount} {"Count"}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "8px",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ width: "20px" }}>
                            <Image
                              src={MinsIcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View style={{ width: "48px" }}>
                            <Text style={{ fontSize: 9, marginTop: "2px" }}>
                              {parseFloat(
                                apiData.totalMobileScreenScreenOnDuration
                              ) < 60
                                ? `${parseFloat(
                                    apiData.totalMobileScreenScreenOnDuration
                                  ).toFixed(2)} Mins`
                                : `${(
                                    parseFloat(
                                      apiData.totalMobileScreenScreenOnDuration
                                    ) / 60
                                  ).toFixed(2)} Hours`}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "8x",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ width: "20px" }}>
                            <Image
                              src={CallCountlcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View style={{ width: "48px" }}>
                            <Text style={{ fontSize: 9, marginTop: "2px" }}>
                              {apiData.totalMobileUseInAcceptedCount} {"Count"}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "8px",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ width: "20px" }}>
                            <Image
                              src={MinsIcon}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </View>
                          <View style={{ width: "48px" }}>
                            <Text style={{ fontSize: 9, marginTop: "2px" }}>
                              {parseFloat(
                                apiData.totalMobileUseInAcceptedDuration
                              ) < 60
                                ? `${parseFloat(
                                    apiData.totalMobileUseInAcceptedDuration
                                  ).toFixed(2)} Mins`
                                : `${(
                                    parseFloat(
                                      apiData.totalMobileUseInAcceptedDuration
                                    ) / 60
                                  ).toFixed(2)} Hours`}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "100px",
                          height: "170px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <Image
                            src={Countlcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            {apiData.riskSlot10count} {"Count"}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={LocationIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            {apiData.riskSlot10Distance} {"Kms"}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={MinsIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            {apiData.riskSlot10Duration} {"Mins"}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "100px",
                          height: "170px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <Image
                            src={RdIcon}
                            style={{ width: "15px", height: "15px" }}
                          />

                          <View>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "8px",
                                marginTop: "2px",
                              }}
                            >
                              Start Time
                            </Text>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "8px",
                                marginTop: "4px",
                              }}
                            >
                              {`${UtilDateTime.formatDateTimeNew(
                                apiData.startTime
                              )}`}
                            </Text>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "8px",
                                marginTop: "4px",
                              }}
                            >
                              {`${apiData.startDateFormate}`}
                            </Text>

                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "8px",
                                marginTop: "5px",
                              }}
                            >
                              End Time
                            </Text>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "8px",
                                marginTop: "4px",
                              }}
                            >
                              {`${UtilDateTime.formatDateTimeNew(
                                apiData.endTime
                              )}`}
                            </Text>
                            <Text
                              style={{
                                fontSize: 9,
                                marginLeft: "8px",
                                marginTop: "4px",
                              }}
                            >
                              {`${apiData.endDateFormate}`}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={BattryIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            {apiData.batterylevelTotalValue < 0
                              ? `Battery Consumption | ${Math.abs(
                                  apiData.batterylevelTotalValue
                                )}% `
                              : apiData.batterylevelTotalValue == 0
                              ? "No Battery Consumption"
                              : "Battery Charged"}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Image
                            src={JvIcon}
                            style={{ width: "15px", height: "15px" }}
                          />
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            {/* {apiData.totalHighRiskCount}{" "}{"Count"} */}
                          </Text>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "3px",
                            justifyContent: "center",
                            marginBottom: "3px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "1px",
                            }}
                          >
                            {apiData.event}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          width: "100px",
                          height: "170px",
                          padding: 5,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderLeft: "1px solid lightgrey",
                        }}
                      >
                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            U{" - "}
                            {apiData.urbanKilometers} {"Kms"}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            R{" - "}
                            {apiData.ruralKilometers} {"Kms"}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            H{" - "}
                            {apiData.highwayKilometers} {"Kms"}
                          </Text>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            D{" - "}
                            {apiData.day} {"%"}
                          </Text>
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "5px",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 9,
                              marginLeft: "8px",
                              marginTop: "2px",
                            }}
                          >
                            N{" - "}
                            {apiData.night} {"%"}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                ))}

                {/* Ride Details Summary End */}

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                    marginBottom: 15,
                  }}
                >
                  <View>
                    <Text style={{ fontSize: 13, marginBottom: 5 }}>
                      2. Risk Alerts
                    </Text>
                  </View>

                  {/* <View style={{ marginLeft: "5px" }}>
                    <Text style={{ fontSize: 13, marginBottom: 5 }}>{`(`}</Text>
                  </View>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Text
                        style={{
                          fontSize: "9px",
                          marginBottom: 2,
                          textAlign: "center",
                        }}
                      >
                        Duration:
                      </Text>

                      <Text
                        style={{
                          fontSize: "9px",
                          marginBottom: 2,
                          textAlign: "center",
                          marginLeft: "3px",
                          fontFamily: "Helvetica-Bold",
                        }}
                      >
                        {filtersUserPage.durationFilter == "" ? "All" : filtersUserPage.durationFilter == "CUSTOM_DURATION" ? `${UtilDateTime.formatDateIntSlash(filtersUserPage.startDateTime)} - ${UtilDateTime.formatDateIntSlash(filtersUserPage.endDateTime)}` : ValueFormat.capitalizeFirstLetterAndFormat(filtersUserPage.durationFilter)}
                      </Text>
                    </View>
                  </View>

                  <View style={{ marginLeft: "1px" }}>
                    <Text style={{ fontSize: 13, marginBottom: 1 }}>{`)`}</Text>
                  </View> */}
                </View>

                <View>
                  {/* {riskDataListleng === true ? (
                                                                                    ""
                                                                                  ) : riskDataListleng === false ? (
                                                                                    <p className="data_not_available mb-2">
                                                                                      {displayText.DATA_NOT_AVAILABLE}
                                                                                    </p>
                                                                                  ) : riskDataListleng === "failed" ? (
                                                                                    <p className="data_not_available mb-2">{displayText.ER_1206}</p>
                                                                                  ) : (
                                                                                    <p className="fetch_data mb-2">{displayText.FETCHING_DATA}</p>
                                                                                  )} */}

                  <View style={styles.gridContainer}>
                    {rows.map((row, rowIndex) => (
                      <View key={rowIndex} style={styles.rowContainer}>
                        {row.map((widget, widgetIndex) => (
                          <View key={widgetIndex} style={styles.cardContainer}>
                            <View style={styles.cardContent}>
                              {/* Image */}
                              <View style={styles.imageContainer}>
                                <Image
                                  src={widget.image}
                                  style={styles.image}
                                />
                              </View>

                              {/* Title */}
                              <View style={styles.titleContainer}>
                                <Text style={styles.title}>{widget.title}</Text>
                              </View>

                              {/* Data */}
                              <View
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Text style={styles.dataText}>
                                  {widget.overallData ? widget.overallData : 0}
                                </Text>
                              </View>
                            </View>
                          </View>
                        ))}
                      </View>
                    ))}
                  </View>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                    marginBottom: 15,
                  }}
                >
                  
                  <View>
                    <Text style={{ fontSize: 13, marginBottom: 5 }}>
                      3. Risk Histogram
                    </Text>
                  </View>
                  </View>

                  <View style={{display:"flex",flexDirection:"row",gap:5}}>

                    <View style={{display:"flex",flexDirection:"column",border:"1px solid lightgrey",width:"100%"}}>

                      <View style={{display:"flex",flexDirection:'row',borderBottom:"1px solid lightgrey",height:"40px",backgroundColor:"#E0E0E0",marginBottom:"8px"}}>

                        <View style={{display:"flex",flexDirection:"column",width:"30%",justifyContent:"center"}}>

                        <Text style={{ fontSize: "9px",color:"#000000",fontWeight:600,textAlign:"center"}}>
                          Distribution
                        </Text>

                        <Text style={{ fontSize: "9px",color:"#000000",fontWeight:600,textAlign:"center"}}>
                          Range
                        </Text>

                        </View>

                        <View style={{display:"flex",flexDirection:"column",width:"100%",justifyContent:"center"}}>

                        <Text style={{ fontSize: "9px",color:"#000000",fontWeight:600,textAlign:"center"}}>
                         Ratio
                        </Text>

                        </View>

                        <View style={{display:"flex",flexDirection:"column",width:"30%",justifyContent:"center"}}>

                        <Text style={{ fontSize:"9px",color:"#000000",fontWeight:600,textAlign:"center"}}>
                         Value
                        </Text>

                        </View>                        


                      </View>

                      {combinedArray?.slice(0,5).map((progress, index) => (
                        <View key={index} style={{display:"flex",flexDirection:'row',height:"40px"}}>

                        <View style={{display:"flex",flexDirection:"column",width:"30%",justifyContent:"center"}}>

                        <Text style={{ fontSize: "9px",color:"#000000",fontWeight:600,textAlign:"center"}}>
                          {progress?.label}
                        </Text>

                       

                        </View>

                        <ProgressBar  progress={progress} />

                        <View style={{display:"flex",flexDirection:"column",width:"30%",justifyContent:"center"}}>

                        <Text style={{ fontSize:"9px",color:"#000000",fontWeight:600,textAlign:"center"}}>
                        {`${progress?.value}%`}
                        </Text>

                        </View>                        


                      </View>))}
                    </View>

                    <View style={{display:"flex",flexDirection:"column",border:"1px solid lightgrey",width:"100%"}}>

                      <View style={{display:"flex",flexDirection:'row',borderBottom:"1px solid lightgrey",height:"40px",backgroundColor:"#E0E0E0",marginBottom:"8px"}}>

                        <View style={{display:"flex",flexDirection:"column",width:"30%",justifyContent:"center"}}>

                        <Text style={{ fontSize: "9px",color:"#000000",fontWeight:600,textAlign:"center"}}>
                          Distribution
                        </Text>

                        <Text style={{ fontSize: "9px",color:"#000000",fontWeight:600,textAlign:"center"}}>
                          Range
                        </Text>

                        </View>

                        <View style={{display:"flex",flexDirection:"column",width:"100%",justifyContent:"center"}}>

                        <Text style={{ fontSize: "9px",color:"#000000",fontWeight:600,textAlign:"center"}}>
                         Ratio
                        </Text>

                        </View>

                        <View style={{display:"flex",flexDirection:"column",width:"30%",justifyContent:"center"}}>

                        <Text style={{ fontSize:"9px",color:"#000000",fontWeight:600,textAlign:"center"}}>
                         Value
                        </Text>

                        </View>                        


                      </View>

                      {combinedArray?.slice(5,9).map((progress, index) => (
                        <View key={index} style={{display:"flex",flexDirection:'row',height:"40px"}}>

                        <View style={{display:"flex",flexDirection:"column",width:"30%",justifyContent:"center"}}>

                        <Text style={{ fontSize: "9px",color:"#000000",fontWeight:600,textAlign:"center"}}>
                          {progress?.label}
                        </Text>

                       

                        </View>

                        <ProgressBar  progress={progress} />

                        <View style={{display:"flex",flexDirection:"column",width:"30%",justifyContent:"center"}}>

                        <Text style={{ fontSize:"9px",color:"#000000",fontWeight:600,textAlign:"center"}}>
                        {`${progress?.value}%`}
                        </Text>

                        </View>                        


                      </View>))}
                    </View>
                  </View>

                  <View style={{marginTop:20,marginBottom: 15,display:"flex",flexDirection:"row",gap:5,width:"100%"}}>
                    
                    <View style={{display:"flex",flexDirection:"column",width:"100%"}}>
                    <View style={{display:"flex",flexDirection:"row",marginBottom: 15,}}>
                                                                      <View>
                                                                      <Text style={{ fontSize: 13, marginBottom: 5}}>
                                                        4. Driver State Detection
                                                      </Text>
                                                                      </View>                             
                                                                        
                                                                      
                                                                      </View>
                  
                          <View style={styles.row}>
                                {/* Render cells for each row */}
                                
                                  <View  style={styles.cellHeader}>
                                   
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",borderRight:"1px solid lightgrey",height:"100%"}}>
                  
                                      <Text style={styles.headerText}>Driver State</Text>
                  
                                      </View>
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>Count</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>Kms</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>
                  
                                      <Text style={styles.headerText}>Time</Text>
                  
                                      </View>
                                      
                  
                                    
                  
                                    
                                    
                                  </View>
                                
                           
                                  </View>
                  
                          <View style={styles.table}>
                            
                              <View  style={styles.row}>
                                {/* Render cells for each row */}
                             
                                  <View  style={styles.cell}>
                                   
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",borderRight:"1px solid lightgrey",height:"100%"}}>
                  
                                      <Text style={styles.mapText}>Normal</Text>
                  
                                      </View>
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStateList?.driverNormal}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStateList?.distanceStateNormal}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStateList?.durationStateNormal}</Text>
                  
                                      </View>
                  
                                    
                  
                                    
                                    
                                  </View>
                               
                              </View>

                              <View  style={styles.row}>
                                {/* Render cells for each row */}
                             
                                  <View  style={styles.cell}>
                                   
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",borderRight:"1px solid lightgrey",height:"100%"}}>
                  
                                      <Text style={styles.mapText}>Tired</Text>
                  
                                      </View>
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStateList?.driverTried}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStateList?.distanceStateTired}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStateList?.durationStateTired}</Text>
                  
                                      </View>
                  
                                    
                  
                                    
                                    
                                  </View>
                               
                              </View>

                              <View  style={styles.row}>
                                {/* Render cells for each row */}
                             
                                  <View  style={styles.cell}>
                                   
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",borderRight:"1px solid lightgrey",height:"100%"}}>
                  
                                      <Text style={styles.mapText}>Angry</Text>
                  
                                      </View>
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStateList?.driverAngry}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStateList?.distanceStateAngry}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStateList?.durationStateAngry}</Text>
                  
                                      </View>
                  
                                    
                  
                                    
                                    
                                  </View>
                               
                              </View>

                           
                          </View>
                          </View>

                          <View style={{display:"flex",flexDirection:"column",width:"100%"}}>
                    <View style={{display:"flex",flexDirection:"row",marginBottom: 15,}}>
                                                                      <View>
                                                                      <Text style={{ fontSize: 13, marginBottom: 5}}>
                                                        5. Driver Style Estimation
                                                      </Text>
                                                                      </View>                             
                                                                        
                                                                      
                                                                      </View>
                  
                          <View style={styles.row}>
                                {/* Render cells for each row */}
                                
                                  <View  style={styles.cellHeader}>
                                   
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",borderRight:"1px solid lightgrey",height:"100%"}}>
                  
                                      <Text style={styles.headerText}>Driver Style</Text>
                  
                                      </View>
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>Count</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>Kms</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>
                  
                                      <Text style={styles.headerText}>Time</Text>
                  
                                      </View>
                                      
                  
                                    
                  
                                    
                                    
                                  </View>
                                
                           
                                  </View>
                  
                          <View style={styles.table}>
                            
                              <View  style={styles.row}>
                                {/* Render cells for each row */}
                             
                                  <View  style={styles.cell}>
                                   
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",borderRight:"1px solid lightgrey",height:"100%"}}>
                  
                                      <Text style={styles.mapText}>Normal</Text>
                  
                                      </View>
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.drivingNormal}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.distanceStyleNormal}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.durationStyleNormal}</Text>
                  
                                      </View>
                  
                                    
                  
                                    
                                    
                                  </View>
                               
                              </View>

                              <View  style={styles.row}>
                                {/* Render cells for each row */}
                             
                                  <View  style={styles.cell}>
                                   
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",borderRight:"1px solid lightgrey",height:"100%"}}>
                  
                                      <Text style={styles.mapText}>Sportive</Text>
                  
                                      </View>
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.drivingSportive}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.distanceStyleSportive}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.durationStyleSportive}</Text>
                  
                                      </View>
                  
                                    
                  
                                    
                                    
                                  </View>
                               
                              </View>

                              <View  style={styles.row}>
                                {/* Render cells for each row */}
                             
                                  <View  style={styles.cell}>
                                   
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",borderRight:"1px solid lightgrey",height:"100%"}}>
                  
                                      <Text style={styles.mapText}>Eco</Text>
                  
                                      </View>
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.drivingEco}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.distanceStyleEco}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.durationStyleEco}</Text>
                  
                                      </View>
                  
                                    
                  
                                    
                                    
                                  </View>
                               
                              </View>

                           
                          </View>
                          </View>

                          <View style={{display:"flex",flexDirection:"column",width:"100%"}}>
                    <View style={{display:"flex",flexDirection:"row",marginBottom: 15,}}>
                                                                      <View>
                                                                      <Text style={{ fontSize: 13, marginBottom: 5}}>
                                                       {(" ")} {/* 5. Driver Style Estimation */}
                                                      </Text>
                                                                      </View>                             
                                                                        
                                                                      
                                                                      </View>
                  
                          <View style={styles.row}>
                                {/* Render cells for each row */}
                                
                                  <View  style={styles.cellHeader}>
                                   
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",borderRight:"1px solid lightgrey",height:"100%"}}>
                  
                                      <Text style={styles.headerText}>Driver Style</Text>
                  
                                      </View>
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>Count</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>Kms</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>
                  
                                      <Text style={styles.headerText}>Time</Text>
                  
                                      </View>
                                      
                  
                                    
                  
                                    
                                    
                                  </View>
                                
                           
                                  </View>
                  
                          <View style={styles.table}>
                            
                              <View  style={styles.row}>
                                {/* Render cells for each row */}
                             
                                  <View  style={styles.cell}>
                                   
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",borderRight:"1px solid lightgrey",height:"100%"}}>
                  
                                      <Text style={styles.mapText}>Chaotic</Text>
                  
                                      </View>
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.drivingChaotic}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.distanceStyleChaotic}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.durationStyleChaotic}</Text>
                  
                                      </View>
                  
                                    
                  
                                    
                                    
                                  </View>
                               
                              </View>

                              <View  style={styles.row}>
                                {/* Render cells for each row */}
                             
                                  <View  style={styles.cell}>
                                   
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",borderRight:"1px solid lightgrey",height:"100%"}}>
                  
                                      <Text style={styles.mapText}>Aggression</Text>
                  
                                      </View>
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.drivingAggressive}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.distanceStyleAggressive}</Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>
                  
                                      <Text style={styles.headerText}>{rideDetailsDriverStyleList?.durationStyleAggressive}</Text>
                  
                                      </View>
                  
                                    
                  
                                    
                                    
                                  </View>
                               
                              </View>

                              <View  style={styles.row}>
                                {/* Render cells for each row */}
                             
                                  <View  style={styles.cell}>
                                   
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",borderRight:"1px solid lightgrey",height:"100%"}}>
                  
                                      <Text style={styles.mapText}></Text>
                  
                                      </View>
                  
                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}></Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%",borderRight:"1px solid lightgrey"}}>
                  
                                      <Text style={styles.headerText}></Text>
                  
                                      </View>

                                      <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>
                  
                                      <Text style={styles.headerText}></Text>
                  
                                      </View>
                  
                                    
                  
                                    
                                    
                                  </View>
                               
                              </View>

                           
                          </View>
                          </View>

                          



                          </View>

                    

              </View>
            </View>

            {/* <PdfFooterV1 pageNumber={1} totalPages={2} pathname={pathname} /> */}
          </Page>

          
        </Document>
      </PDFViewer>
    </React.Fragment>
  );
}
